import * as React from "react"

import GalleryItems from "./items"

interface IGalleryUploaderProps {
  defaultItems?: any[]
  onChange?: (items: any[]) => void
  onItemClick?: (item: any, index: number) => void
  primaryIndex?: number
  showActions?: boolean
  isSingular?: boolean
  lgcols?: number
  title?: string
  description?: string
}

const GalleryUploader: React.FunctionComponent<IGalleryUploaderProps> = ({
  defaultItems,
  onChange,
  onItemClick,
  primaryIndex,
  isSingular = false,
  lgcols,
  showActions = true,
  title,
  description
}) => {
  const [items, setItems] = React.useState<any[]>([])
  React.useEffect(() => {
    if (defaultItems) {
      setItems(defaultItems)
    }
    return () => setItems([])
  }, [defaultItems])

  const handleRemove = (index: number) => {
    setItems((crt) => crt.filter((item, i) => i !== index))
  }

  const handleAddMedia = (file: any) => {
    if (isSingular) {
      setItems(() => {
        const newItems = [file]
        if (onChange) onChange(newItems)
        return newItems
      })
      return
    }
    setItems((crt) => {
      const newItems = [...crt, file]

      if (onChange) onChange(newItems)

      return newItems
    })
  }

  return (
    <GalleryItems
      lgcols={lgcols}
      items={items}
      handleRemove={handleRemove}
      handleAddMedia={handleAddMedia}
      onItemClick={onItemClick}
      primaryIndex={primaryIndex}
      hasPrimaryChange={primaryIndex !== undefined}
      showActions={showActions}
      title={title}
      description={description}
    />
  )
}

export default GalleryUploader
