import { actionSetLogout } from "@/store/slices/auth"
import axios from "axios"
import { store } from "@/store/store"
import { t } from "i18next"
import { toaster } from "@/components/shared/toaster"
import { tokenSelector } from "@/store/slices/auth/selectors"

/**
 * axios instance and interceptors are created here.
 * Will subscribe to redux store later on.
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/api`,
  headers: {
    Accept: "application/json, text/plain, */*"
  }
})

/*api.interceptors.request.use(
  async (config) => {
    if (config.method === "post" || config.method === "put") {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_BASE}/sanctum/csrf-cookie`,
          config
        )
        return config
      } catch (e) {
        throw new Error("Something went wrong in sanncturm csrf cookie!")
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)*/

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (403 === error.response.status) {
      store.dispatch(actionSetLogout())
      toaster.warning(t("session_expired"))
    } else {
      return Promise.reject(error)
    }
  }
)

store.subscribe(() => {
  const headers = api.defaults.headers as any
  headers["Authorization"] = `Bearer ${tokenSelector(store.getState())}`
})

export default api
