import { IMedia } from "@/types/media"
import { IProperty } from "@/types/properties"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

//Initial state type
interface IinitState extends Partial<IProperty> {}
//Initial state

export interface IServerProperty extends IProperty {
  id: string
  created_at: string
  updated_at: string
  expires_at: string
  sold_shares: number
  media: IMedia[]
  status: string //should be change in the future
}
export const initialProjectState: IinitState = {
  title: "",
  description: "",
  background: "",
  excerpt: "",
  owner_name: "",
  monthly_wage: "",
  investment_period_months: null,
  is_guaranteed: null,
  min_investment_quantity: null,
  max_investment_quantity: null,
  investment_ratio: "",
  risk_statement: "",
  media: [],
  finance_statement: "",
  company_statement: "",
  market_statement: "",
  advantage_statement: "",
  status: "",
  total_shares: "",
  meta: ""
}

const propertySlice = createSlice({
  name: "property",
  initialState: initialProjectState,
  reducers: {
    changeProperty(state, action: PayloadAction<any>) {
      if (action.payload && Object.keys(action.payload).length) {
        for (let key of Object.keys(action.payload)) {
          state[key] = action?.payload?.[key]
        }
      }
    },
    resetProperty: () => initialProjectState,
    changeMedia(state, action: PayloadAction<any>) {
      let currentMedia = [...state.media]
      if (currentMedia.indexOf(action?.payload) !== -1) {
        state.media = currentMedia.filter(
          (media) => media?.id !== action.payload?.id
        ) as any
      } else {
        state.media = [...currentMedia, action.payload] as any
      }
    },
    changeProjectCover(state, action: PayloadAction<any>) {
      state.background = action.payload
    },
    removeSingleMedia(state, action: PayloadAction<any>) {
      state.media = state.media.filter((i) => i.id !== action.payload.id)
    }
  }
})

export const {
  changeProperty: actionChangeProperty,
  resetProperty: actionResetProperty,
  changeMedia: actionChangeMedia,
  changeProjectCover: actionChangeProjectCover,
  removeSingleMedia: actionRemoveSingleMedia
} = propertySlice.actions

export default propertySlice.reducer
