import type {} from "@mui/lab/themeAugmentation";
import "@mui/lab/themeAugmentation";

//External import
import { Theme, alpha, createTheme } from "@mui/material";

import cssBaselineOverrides from "./cssBaselineOverrides";
//Internal import
import { lightPalette } from "../palettes";
import variantsGenerator from "./typographyVariantsGenerator";

/**
 * Place all theme related properties here (except for palette)
 * @param {boolean} dark is used to switch theme
 * Since designs currently have only one theme, don't provide dark argument
 */

const createMyTheme = (dark?: boolean): Theme => {
  const palette = lightPalette(dark ? "dark" : "light");
  const textColor = dark ? `rgba(255,255,255,0.87)` : `rgba(0,0,0,0.87)`;
  const tabsBgcolor = dark
    ? `${alpha(palette.primary.main, 0.22)}`
    : `${alpha(palette.primary.main, 0.1)}`;
  const theme = createTheme({
    palette,
    direction: "rtl",
    typography: {
      fontFamily: "Vazir !important",
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: textColor,
          },
        },
      },
      MuiCssBaseline: cssBaselineOverrides(palette),
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
            borderRadius: "5px",
            minHeight: 43,
            minWidth: 120,
          },
        },
        variants: [
          {
            props: { variant: "contained" },
            style: {
              boxShadow: "none",
              color: "white",
            },
          },
        ],
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            [`& .MuiDrawer-paper`]: {
              borderRadius: 0,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            padding: "16px",
            boxShadow: "none",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            [`&`]: {
              borderRadius: 8,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              borderRadius: "5px",
              "& .MuiSelect-outlined": {
                padding: "12.5px 14px",
              },
            },
          },
        },
        defaultProps: {
          autoComplete: "off",
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: "16px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            border: "1px solid",
            padding: "8px 16px",
            marginBottom: "16px",
            borderColor: alpha(palette.common.black, 0.12),
            boxShadow: `0px 2px 5px ${alpha(palette.common.black, 0.06)}`,
            "&.Mui-expanded": {
              marginBottom: "8px !important",
            },
            "& .MuiCollapse-root .MuiCollapse-wrapper": {
              "& .MuiAccordionDetails-root": {
                paddingTop: "20px",
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: tabsBgcolor,
            padding: "8px",
            borderRadius: "40px",
            borderBottom: "0px !important",
            "& .MuiTabs-scroller ": {
              "& .MuiTabs-flexContainer button": {
                "&.Mui-selected": {
                  background: palette.primary.main,
                  borderRadius: "40px",
                  color: palette.common.white,
                },
                fontSize: 16,
              },
              "& span": {
                display: "none",
              },
            },
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            "& .MuiTimelineDot-root": {
              backgroundColor: palette.primary.dark,
              margin: "0px !important",
              width: "24px",
              height: "24px",
            },
            "& .MuiTimelineConnector-root": {
              backgroundColor: alpha(palette.primary.dark, 0.2),
              width: "6px !important",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiMenu-paper": {
              boxShadow: `0 1px 4px ${alpha(palette.common.black, 0.2)}`,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(21, 53, 90, 0.34)",
            backdropFilter: "blur(7px)",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 46,
            height: 26,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: "1px 2px",
              margin: 2,
              transitionDuration: "300ms",
              "&.Mui-checked": {
                borderColor: "transparent",
                transform: "translateX(18px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor:
                    palette.mode === "dark" ? "#2ECA45" : "#E29314",
                  opacity: 1,
                  border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 1,
                },
                "& .MuiSwitch-thumb": {
                  boxSizing: "border-box",
                  width: 20,
                  height: 20,
                  backgroundColor: "#fff",
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                  palette.mode === "light"
                    ? palette.grey[100]
                    : palette.grey[600],
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: palette.mode === "light" ? 0.7 : 0.3,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 20,
              height: 20,
              backgroundColor: palette.mode === "light" ? "#737277" : "#39393D",
            },
            "& .MuiSwitch-track": {
              borderRadius: 26 / 2,
              border: "1px solid",
              borderColor: "#737277",
              backgroundColor:
                palette.mode === "light" ? "transparent" : "#39393D",
              opacity: 1,
            },
          },
        },
      },
    },
  });

  const media = theme.breakpoints.down("md");

  /* Responsive typographies here (only base typographies(for example header. not headerBold)) or default MUI */
  theme.typography.header = {
    fontSize: "60px",
    lineHeight: "94px",
    [media]: {
      fontSize: "24px",
      lineHeight: "38px",
    },
  };

  theme.typography.large = {
    fontSize: "48px",
    lineHeight: "75px",
    [media]: {
      fontSize: "22px",
      lineHeight: "28px",
    },
  };
  theme.typography.subheading = {
    fontSize: "32px",
    lineHeight: "39px",
    [media]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
  };
  theme.typography.heading = {
    fontSize: "24px",
    lineHeight: "39px",
    [media]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  };
  theme.typography.subtitle = {
    fontSize: "18px",
    lineHeight: "30px",
    [media]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  };
  theme.typography.body = {
    fontSize: "16px",
    lineHeight: "26px",
    [media]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  };
  theme.typography.small = {
    fontSize: "14px",
    lineHeight: "22px",
    [media]: {
      fontSize: "12px",
      lineHeight: "20px",
    },
  };
  theme.typography.extraSmall = {
    fontSize: "12px",
    lineHeight: "20px",
    [media]: {
      fontSize: "10px",
      lineHeight: "16px",
    },
  };
  theme.typography = {
    ...theme.typography,
    ...variantsGenerator(theme.typography),
  };

  return theme;
};

export default createMyTheme;
