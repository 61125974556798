/**
 * Place all endpoints here
 */

type ParamsType = string | number | boolean
type NoParamKeys =
  | "projects"
  | "transactions"
  | "events"
  | "loginViaCredential"
  | "confirmOtp"
  | "loginViaMobile"
  | "resetPassword"
  | "resendOtp"
  | "users"
  | "tickets"
  | "roles"
  | "gateway"
  | "permissions"
  | "notifications"
  | "notificationsStatistics"
  | "exportExcelTranasction"
  | "profile"
  | "events"
  | "reports"
  | "dashboardSummary"
  | "financeSummary"
  | "exportUsers"

type ParamKeys =
  | "hasParam"
  | "singleUser"
  | "singleReport"
  | "projectInvestment"
  | "singleProject"
  | "singleProjectPartial"
  | "singleNotification"
  | "ticketMessages"
  | "eventApproval"
  | "role"
  | "rolePartial"
  | "revokeRole"
  | "projectInvestors"
  | "event"
  | "projectsStatistics"
  | "calcProjectProfit"
  | "projectTransactions"
  | "statistics"
  | "transactionVerify"
  | "transactionPartial"
  | "transactionPayback"
  | "categories"
  | "singleCategory"
  | "posts"
  | "singlePost"
  | "attachments"

type ParamsGenerator<T extends ParamKeys> = {
  [k in (typeof endpoints)[T]["params"][number]]: ParamsType
}

type FunctionOverload = {
  <T extends NoParamKeys>(key: T): string
  <T extends ParamKeys>(key: T, params: ParamsGenerator<T>): string
}

/**
 * endpoints must have the following naming
 * @example ```js
 * const endpoints = {
 *    "endpoint-key": {
 *      endpoint: "path/to/my/endpoint/" or "path/to/:my/endpoint/:params",
 *      params: false or ["my", "params"]
 *    }
 * }
 * This way typescript will suggest you with required params
 * ```
 */
export const endpoints = {
  projects: {
    endpoint: "/projects"
  },
  events: {
    endpoint: "/events"
  },
  reports: {
    endpoint: "/reports"
  },
  singleReport: {
    endpoint: "/reports/:report_id",
    params: ["report_id"]
  },
  loginViaCredential: {
    endpoint: "/auth/login/via-credential"
  },
  loginViaMobile: {
    endpoint: "/auth/login/via-otp/step-one"
  },
  resetPassword: {
    endpoint: "/users/password"
  },
  dashboardSummary: {
    endpoint: "/statistics/total"
  },
  singleProject: {
    endpoint: "/projects/:id",
    params: ["id"]
  },
  projectsStatistics: {
    endpoint: "/projects/statistics/:partial",
    params: ["partial"]
  },
  statistics: {
    endpoint: "/statistics/:partial",
    params: ["partial"]
  },

  event: {
    endpoint: "/events/:event_id",
    params: ["event_id"]
  },
  singleProjectPartial: {
    endpoint: "/projects/:id/:partial",
    params: ["id", "partial"]
  },
  confirmOtp: {
    endpoint: "/auth/login/via-otp/step-two"
  },
  notifications: {
    endpoint: "/notifications"
  },
  eventApproval: {
    endpoint: "/events/:event_id/acceptance",
    params: ["event_id"]
  },
  profile: {
    endpoint: "/profile"
  },
  tickets: {
    endpoint: "/tickets"
  },
  gateway: {
    endpoint: "/gateway"
  },
  posts: {
    endpoint: "/:type/posts",
    params: ["type"]
  },
  singlePost: {
    endpoint: "/:type/posts/:id",
    params: ["type", "id"]
  },
  exportExcelTranasction: {
    endpoint: "/wallets/transactions/export/excel"
  },
  attachments: {
    endpoint: "/:type/posts/:id/attachments/",
    params: ["type", "id"]
  },
  ticketMessages: {
    endpoint: "tickets/:id/messages",
    params: ["id"]
  },
  users: {
    endpoint: "/users"
  },
  exportUsers: {
    endpoint: "/users/export/excel"
  },
  transactions: {
    endpoint: "/wallets/transactions"
  },
  transactionVerify: {
    endpoint: "/wallets/transactions/:transaction_id/verify",
    params: ["transaction_id"]
  },
  transactionPayback: {
    endpoint: "/wallets/transactions/:transaction_id/payback",
    params: ["transaction_id"]
  },
  transactionPartial: {
    endpoint: "/wallets/transactions/:transaction_id/:type",
    params: ["transaction_id", "type"]
  },
  financeSummary: {
    endpoint: "/projects/statistics/total"
  },
  projectTransactions: {
    endpoint: "/projects/:project/transactions",
    params: ["project"]
  },
  roles: {
    endpoint: "/roles"
  },
  revokeRole: {
    endpoint: "/roles/:role_id/revoke",
    params: ["role_id"]
  },
  role: {
    endpoint: "/roles/:role_id",
    params: ["role_id"]
  },
  rolePartial: {
    endpoint: "/roles/:role_id/:partial",
    params: ["role_id", "partial"]
  },
  projectInvestment: {
    endpoint: "/projects/:id/investment",
    params: ["id"]
  },
  projectInvestors: {
    endpoint: "/projects/:id/investors",
    params: ["id"]
  },
  singleNotification: {
    endpoint: "/notifications/:id",
    params: ["id"]
  },
  notificationsStatistics: {
    endpoint: "/notifications/statistics",
    params: ["id"]
  },
  calcProjectProfit: {
    endpoint: "/projects/:project_id/calculate-profit",
    params: ["project_id"]
  },
  permissions: {
    endpoint: "/permissions"
  },
  categories: {
    endpoint: "/:group_type/categories",
    params: ["group_type"]
  },
  singleCategory: {
    endpoint: "/:group_type/categories/:category_id",
    params: ["group_type", "category_id"]
  },
  singleUser: {
    endpoint: "/users/:id",
    params: ["id"]
  },
  hasParam: {
    endpoint: "/param/:myParam",
    params: ["myParam"]
  }
} as const

/**
 * this function returns an endpoint based on panel, key, and params
 * @param key endpoint key inside that panel
 * @param params if the selected endpoint had any params(for example /details/:id), the keys in this argument must match the params of that endpoint({ id: 2 } in this example)
 * @returns the endpoint of the API
 */
const getEndpoint: FunctionOverload = (...args: any[]) => {
  const key = args[0] as keyof typeof endpoints
  const params: any = args?.[1]
  let selectedEndpoint: string = endpoints[key].endpoint
  if (params) {
    for (let key in params) {
      let regex = new RegExp(`\/:${key}`)
      selectedEndpoint = selectedEndpoint.replace(regex, "/" + params[key])
    }
  }
  return selectedEndpoint
}

export default getEndpoint
