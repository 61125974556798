import * as React from "react"

import { Grid, Stack } from "@mui/material"

import GalleryItem from "./item"
import { Uploader } from ".."
import { t } from "i18next"
import { useTranslation } from "react-i18next"
import { useUploader } from "@/hook"

interface IGalleryItemsProps {
  items: any[]
  handleAddMedia: (file: any) => void
  handleRemove: (index: number) => void
  onItemClick?: (item: any, index: number) => void
  primaryIndex: number
  hasPrimaryChange?: boolean
  showActions?: boolean
  lgcols?: number
  title?: string
  description?: string
}

const GalleryItems: React.FunctionComponent<IGalleryItemsProps> = ({
  items = [],
  handleAddMedia,
  handleRemove,
  onItemClick,
  primaryIndex,
  hasPrimaryChange = false,
  showActions = true,
  lgcols = 6,
  title = t("click_to_add_new_image"),
  description = t("description_uploader_image")
}) => {
  const { hiddenInput, file } = useUploader()

  React.useEffect(() => {
    if (!file) return

    handleAddMedia(file)
  }, [file])

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Stack>
        <Grid container spacing={2}>
          {items?.map((item, index) => (
            <Grid item key={index} lg={lgcols} xs={12}>
              <GalleryItem
                item={item}
                index={index}
                handleRemove={handleRemove}
                onItemClick={onItemClick}
                isPrimary={primaryIndex === index}
                hasPrimaryChange={hasPrimaryChange}
                showActions={showActions}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {showActions && (
        <Uploader
          title={title}
          description={description}
          hiddenInput={hiddenInput}
        />
      )}
    </Stack>
  )
}

export default GalleryItems
