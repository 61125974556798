// built-in import
import * as React from "react";

// Internal import
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IMyTabPanelProps, IMyTabsProps } from "@/types/tab";

/**
 * Reusable tabs component, dynamic tab with our unique styles!
 */

/**
 * Add id and ... by index number
 */
function addSomeMetaToTab(tabId: string = "single-tab", index: number) {
  return {
    id: `${tabId}-${index}`,
    "aria-controls": `${tabId}-${index}`,
  };
}

/**
 * Tab Panel, holder of each tab's children!
 */
function TabPanel(props: IMyTabPanelProps) {
  const { children, value, index, tabId, ...other } = props;

  return (
    <Box
      role="tabpanel"
      className="tabpanel-container"
      hidden={value !== index}
      id={`${tabId}-${index}`}
      aria-labelledby={`${tabId}-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 0 }}>{children}</Box>}
    </Box>
  );
}

const MyTabs: React.FunctionComponent<IMyTabsProps> = (props) => {
  const {
    tabId,
    items = [],
    sx = {},
    title = "",
    meta,
    isIsland = false,
    sxParent = {},
  } = props;

  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Getting mui theme
  const theme = useTheme();
  // Detecting viewport of device
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        "& .MuiTabs-flexContainer": {
          height: "100% !important",
          overflow: ["scroll", , "hidden"],
        },
        position: "relative",
        ...(isIsland
          ? {}
          : {
              "& .tabpanel-container": {
                bgcolor: "white",
              },
            }),
        "& .tabpanel-container": {
          width: "100%",
          overflow: "auto",
          borderRadius: "12px",
          mb: 2,
        },
        ...sxParent,
      }}
    >
      <Box
        className="header-tabs"
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
          px: 2,
        }}
      >
        {title ? (
          <Box
            sx={{
              display: "flex",
              px: 2,
            }}
          >
            <Typography variant={"headerBold"}>{title}</Typography>
          </Box>
        ) : null}
        <Stack
          direction="row"
          width="100%"
          sx={{
            border: "1px solid !important",
            borderColor: (t) =>
              alpha(t.palette.common.black, 0.1) + "!important",
            borderRadius: "6px",
            mb: 2,
            pr: 1,
          }}
          alignItems="center"
          justifyContent="space-between"
          className="container-tabs"
        >
          <Tabs
            className="fist-tabb"
            value={activeTab}
            onChange={handleChange}
            aria-label={tabId}
            sx={{
              p: 0,
              width: "100%",
              borderRadius: "6px",
              bgcolor: (t) => t.palette.common.white,
              [`& .MuiTab-root`]: {
                borderRadius: "6px !important",
                maxWidth: "initial",
                pl: 1,
                // width: `${100 / items.length}%`,
                width: "fit-content",
                color: (t) => t.palette.common.black,
                height: "100% !important",
                minHeight: "100%",
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                fontSize: 18,
              },
              "& svg": {
                mx: 1,
              },
              [`& .Mui-selected`]: {
                "& svg": {
                  [`& *`]: {
                    fill: "white",
                  },
                },
              },
              ...sx,
            }}
          >
            {items.map((item, key) => {
              return (
                <Tab
                  label={item.label}
                  {...addSomeMetaToTab(tabId, 0)}
                  icon={item.tabIcon ? item.tabIcon : <></>}
                  iconPosition={"start"}
                  key={key}
                />
              );
            })}
          </Tabs>
          {!isMobile && meta}
        </Stack>
      </Box>
      {items.map((item, key) => {
        return (
          <TabPanel tabId={tabId} key={key} value={activeTab} index={key}>
            {item.children}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default MyTabs;
